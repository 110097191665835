<!-- Insights for uniliver -->
<template>
  <div>
    <div>
      <div class="page-wrapper chiller-theme" style="margin-top: 71px">
        <main class="page-content">
          <div class="container-fluid">
            <div class="d-flex justify-content-between">
              <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
                <!--  <li>
                  <router-link to="/solutions/ul/platform/models">
                    <span>Model</span>
                  </router-link>
                </li> -->
                <li>
                  <router-link to="/solutions/acko/platform/allstats">
                    <span>Model Result</span>
                  </router-link>
                </li>

                <li>
                  <router-link to="/solutions/acko/platform/acid-test">
                    <span>Acid Test</span>
                  </router-link>
                </li>

                <!-- <li>
                  <router-link to="/solutions/acko/platform/stat">
                    <span>Sale Statistics</span>
                  </router-link>
                </li> -->

                <li class="ProximaNovaBold">Causal Insights</li>
                <!-- <li class="ProximaNovaBold" v-if="activeTab === 'Future Planning'">
                  Future Planning
                </li>
                <li class="ProximaNovaBold" v-if="activeTab === 'ROI Summary'">
                  ROI Summary
                </li> -->
              </ul>
              <div v-if="activeTab === 'Causal Insights'" class="d-flex">
                <div class="d-flex flex-row mt-5" style="height: 40%">
                  <div class="w-100 pt-4">
                    <w-white-dropdown :options="brandDropdown" :placeHolder="'Select'" :selectedOption="selectedBrand"
                      :labelText="'Brand'" class="mr-3" @input="selectBrand($event)"></w-white-dropdown>
                  </div>

                  <div class="w-100 pt-4">
                    <w-white-dropdown :options="mediaDropdown" :placeHolder="'Select'" :selectedOption="selectedMedia"
                      :labelText="'Market'" class="mr-3" @input="selectMedia($event)"></w-white-dropdown>
                  </div>
                </div>
                <!--   -->
              </div>
            </div>
          </div>

          <div class="container-fluid mt-5">
            <w-tab class="d-none" :tabName="activeTab" :tabs="tabs" @activeTab="changeTab($event)">
            </w-tab>
            <div v-if="activeTab === 'Causal Insights'">
              <div class="row w-100 mx-0 mt-4">
                <!--     <div class="w-100 p-0">
                  <div class="container-fluid tab-panel p-0">
                    <div class="card w-100">
                      <div class="card-heading">3 years</div>
                      <div class="card-body p-0">
                        <div>
                          <w-chartoverview
                            :chartSummary="threeYearData"
                          ></w-chartoverview>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="w-100 p-0 d-none">
                  <div class="container-fluid tab-panel p-0">
                    <div class="card w-100">
                      <div class="card-heading">1st Jan 2020 to 31st Dec 2020</div>
                      <div class="card-body p-0">
                        <div>
                          <w-chartoverview :chartSummary="alltimeData"></w-chartoverview>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-100 p-0 d-none">
                <div class="container-fluid tab-panel p-0">
                  <div class="card w-100">
                    <div class="card-heading">1st Jan 2021 to 31st Dec 2021</div>
                    <div class="card-body p-0">
                      <div>
                        <w-chartoverview :chartSummary="salseStatics"></w-chartoverview>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-5">
                <b-row class="no-gutters">
                  <b-col lg="6 pr-2">
                    <div class="card">
                      <ColumnChart :chartData="allMobelBarChart" />
                    </div>
                  </b-col>
                  <b-col lg="6 pr-2">
                    <div class="card">
                      <ColumnChart :chartData="internalColumnData" />
                    </div>
                  </b-col>

                  <b-col lg="6 pl-2">
                    <div class="card d-none">
                      <ColumnChart :chartData="regionWiseData" />
                    </div>
                  </b-col>

                  <b-col lg="6 pr-2 d-none">
                    <div class="card">
                      <ColumnChart :chartData="mediaChartData" />
                    </div>
                  </b-col>
                  <b-col lg="6 pl-2 d-none">
                    <div class="card">
                      <ColumnChart :chartData="mediaChartData2" />
                    </div>
                  </b-col>
                </b-row>
              </div>

              <!--        <div class="w-100 pt-4 d-flex flex-row justify-content-center d-none">
                <w-white-dropdown
                  :options="TimeframeDropdown"
                  :placeHolder="'Select'"
                  :selectedOption="selectedTimeframe"
                  :labelText="'Timeframe'"
                  class="mr-3"
                  style="width: 33.33%"
                  @input="selectTimeframe($event)"
                ></w-white-dropdown> -->

              <!--
                <w-white-dropdown
                  :options="marketDropdown"
                  :placeHolder="'Select'"
                  :selectedOption="selectedMarket"
                  :labelText="'Country'"
                  class="mr-3"
                  style="width: 33.33%"
                  @input="selectMarket($event)"
                ></w-white-dropdown>
              </div> -->

              <div class="card h-100 mt-5 d-none">
                <div class="wrapper m-4">
                  <h5 class="card-heading">Attribution by Sales Channel</h5>
                  <div class="dropdown-wrapper d-none">
                    <w-multiDropDown :initialSelectedOptions="selectedChannelList" :options="channelDropList"
                      :maximum="2" @selectedOptions="getSelectedChannelList($event)" ref="channelUpdate" />
                  </div>
                </div>
                <div class="p-4">
                  <TwoBarChart :chartData="channelData" :activeData="activeChannel" @updateContent="updateCampaignType"
                    @updateIndex="updateChannelIndex" ref="updateChannelPerformance" />
                  <TwobarChartSummary :activeChannel="activeChannel" />
                </div>
              </div>

              <!-- <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <ColumnChart :chartData="stackChartData1" />
                </b-container>
              </div> -->

              <!--   <div class="card mt-5 p-5">
                <b-container fluid>
                  <ColumnChart :chartData="mediaChartData" />
                </b-container>
              </div> -->

              <!--   <div class="col-12 col-lg-6 pl-0">
                <div class="card mt-5 p-5">
                  <div class="">
                    <ColumnChart :chartData="stackChartData" />
                  </div>
                </div>
              </div> -->

              <!-- <div class="col-12 col-lg-6 pr-0">
                <div class="card mt-5 p-5">
                  <div class="">
                    <ColumnChart :chartData="mediaChartData" />
                  </div>
                </div>
              </div> -->

              <div class="card mt-5 p-5">
                <b-container fluid>
                  <TwoLevelStripBarChart :stripData="twoLevelStripData.stripData" :title="twoLevelStripData.title"
                    @getDataBreakdown="getStripBreakdown" :secondStripData="secondStripData"
                    @getBarDataBreakdown="getBarDataBreakdown" :barData="twoLevelBarData" />
                </b-container>
              </div>

              <div class="card mt-5 p-5">
                <b-container fluid>
                  <TwoLevelStripBarChart :stripData="twoLevelTVStripData.stripData" :title="twoLevelTVStripData.title"
                    @getDataBreakdown="getTVStripBreakdown" :secondStripData="secondTVStripData"
                    @getBarDataBreakdown="getTVBarDataBreakdown" :barData="twoLevelTVBarData" :customTwoLevelLabel="''"
                    :customThirdLevelLabel="''" :thirdStripData="thirdTVStripData" />
                </b-container>
              </div>

              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Digital :data="digitalExpandData" :stripData="digitalData" @getDataBreakdown="getDigitalBreakdown" />
                </b-container>
              </div>

              <!--for digital performance-->
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <TwoLevelStripBarChart :stripData="twoLevelDigitalPerformanceStripData.stripData"
                    :title="twoLevelDigitalPerformanceStripData.title"
                    @getDataBreakdown="getDigitalPerformanceStripBreakdown"
                    :secondStripData="secondDigitalPerformanceStripData"
                    @getBarDataBreakdown="getDigitalPerformanceBarDataBreakdown"
                    :barData="twoLevelDigitalPerformanceBarData" />
                </b-container>
              </div>

              <div v-if="showPrintAttributionData" class="d-none">
                <div class="card mt-5 p-5">
                  <b-container fluid>
                    <Content :stripData="printData" :title="'Print Attribution by Publisher'" :breakeDownShow="false"
                      :showBreakdownIcon="false" />
                  </b-container>
                </div>
              </div>

              <!--    <div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :stripData="ChannelPerformanceData"
                    :title="'Digital Performance'"
                    :breakeDownShow="false"
                    :showBreakdownIcon="false"
                  />
                </b-container>
              </div> -->
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <line-chart :boxshadow="true" :chartData="lineChartData" />
                </b-container>
              </div>

              <!--   <w-chart-data
                :expandData="invsroiExpandData"
                :dropdownList="tvdropdown"
                :selectedOption="tvTitle"
                :chartData="investRoiData"
                @chartClick="clickedTv($event)"
                @selectedOption="selectedTvDropdown($event)"
                :text="'Investment vs ROI by Channel (Cr)'"
                class="p-0"
              >
              </w-chart-data>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <line-chart :boxshadow="true" :chartData="lineChartData" />
                </b-container>
              </div> -->

              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <ThreeDimensionalChart :boxshadow="true" :chartData="threeDimensionalChartData" />
                </b-container>
              </div>

              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <ColumnChart :chartData="driverContributionData" />
                </b-container>
              </div>

              <!--     <div class="card mt-5 p-5">
                <b-container fluid>
                  <ColumnChart :chartData="stackChartData1" />
                </b-container>
              </div> -->

              <div class="my-5">
                <div class="card pl-5 pb-5 pb-lg-0 h-100 d-none">
                  <div class="d-flex mx-3 mt-3" style="justify-content: space-between; align-items: center">
                    <h5 class="card-heading"></h5>
                    <!-- <w-white-dropdown
                      :placeHolder="'Select'"
                      :selectedOption="selectedDuration"
                      :labelText="'Duration'"
                      class="mr-3"
                      @input="selectDuration($event)"
                      :options="durationDropdown"
                    /> -->
                  </div>
                  <ColumnChart :chartData="stackChartData1" />
                </div>
              </div>

              <div class="my-5 d-none">
                <div class="card pl-5 pb-5 pb-lg-0 h-100">
                  <div class="d-flex mx-3 mt-3" style="justify-content: space-between; align-items: center">
                    <h5 class="card-heading">* #8. Models Comparison with Time Series</h5>
                    <div class="d-flex flex-row">
                      <w-white-dropdown :placeHolder="'Select'" :selectedOption="selectedModel" :labelText="'Models'"
                        class="mr-3" @input="selectModel($event)" :options="modelsDropdown" />
                      <w-white-dropdown :placeHolder="'Select'" :selectedOption="selectedDuration"
                        :labelText="'Duration'" class="mr-3" @input="selectDuration1($event)"
                        :options="durationDropdown" />
                    </div>
                  </div>
                  <ColumnChart :chartData="stackChartData2" />
                </div>
              </div>
              <!--Performance dashboard -->

              <!--<div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :stripData="sponsorshipData"
                    :title="'Sponsorship'"
                    :breakeDownShow="false"
                    :showBreakdownIcon="false"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="contentExpandData"
                    :stripData="contentData"
                    @getDataBreakdown="getContentBreakdown"
                  />
                </b-container>
              </div>-->
              <!--<div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="billboardExpandData"
                    :title="'Billboard'"
                    :stripData="billboardData"
                    @getDataBreakdown="getBillboardBreakdown"
                  />
                </b-container>
              </div>-->
            </div>
            <w-summary v-if="activeTab === 'ROI Summary'"></w-summary>
            <w-future-planning v-if="activeTab === 'Future Planning'"></w-future-planning>
          </div>
          <w-button @buttonClicked="handleNext" class="mr-5 mb-5" style="margin-bottom: 30px" :buttonText="'Next'" />
          <!---  <page-loader v-if="count < 6"></page-loader> -->
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import Tab from "@/components/Solutions/Tab.vue";
// import PieChart from "@/components/Chart/PieChart.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
// import MultipleDonutChart from "@/components/Chart/MultipleDonutChart.vue";
import ROISummary from "./ROI-Summary.vue";
import FuturePlanning from "@/pages/DEMO/AIMO-UL/FuturePlanning.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
// import PageLoader from "@/widgets/PageLoader.vue";
import TwoLevelStripBarChart from "@/components/Solutions/TwoLevelStripBarChart.vue";
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import LineChart from "@/components/Chart/LineChart.vue";
// import ChartWiseData from "@/components/Summary/ChannelWiseData.vue";
import ThreeDimensionalChart from "@/components/Chart/ThreeDimensionalChart.vue";
import MultiDropDown from "@/widgets/MultiDropDown.vue";
import TwoBarChart from "@/components/Chart/TwoBarChart.vue";
import TwobarChartSummary from "@/widgets/TwobarchartSummary.vue";
import Button from "@/components/Profile/Button.vue";

import { AimoSolutionsServices } from "@/services/AimoSolutionsServices.js";
import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
const aimoUnileverService = new AimoUnileverServices();
const aimoSolutionsServices = new AimoSolutionsServices();

export default {
  name: "Insights",
  components: {
    // PageLoader,
    LineChart,
    "w-summary": ROISummary,
    ColumnChart,
    "w-tab": Tab,
    "w-white-dropdown": WhiteDropdown,
    "w-future-planning": FuturePlanning,
    "w-chartoverview": ChartOverview,
    // "w-chart-data": ChartWiseData,
    "w-multiDropDown": MultiDropDown,
    "w-button": Button,
    // PieChart,
    Digital,
    Content,
    TwoLevelStripBarChart,
    ThreeDimensionalChart,
    TwoBarChart,
    TwobarChartSummary,
    // MultipleDonutChart,
  },
  data() {
    return {
      // resData: res,
      showBreakdownChart: false,
      clientId: sessionStorage.getItem("subId"),
      marketType: localStorage.getItem("selectedKPISource"),
      secondDigitalPerformanceStripData: true,
      isPrintAttributionShown: true,
      activeChannel: "Bengaluru",
      tvTitle: {},
      tvdropdown: [],
      secondStripData: [],
      allMobelBarChart: {
        data: [],
        title: {
          text: "Attribution by Channel for All Models(%)",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#faacfe",
          "#67e8ff",
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#d81b60",
          "#fb8c00",
        ],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          layout: "horizontal",
          itemMarginTop: 30,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        xAxis: {
          categories: ["2017", "2018", "2019"],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.3,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      secondTVStripData: [],
      thirdTVStripData: [],
      channelData: {
        startIndex: 0,
        endIndex: 7,
        height: "470",
        middleData: [
          "Bengaluru",
          "Chennai",
          "Delhi",
          "Hyderabad",
          "Kolkata",
          "Lucknow",
          "Mumbai",
          // "Print",
          // "Social",
        ],
        categories: ["TV", "Digital", "Print", "Aggregators", "News paper"],
        colors: ["#b5a9ff", "#67e8ff"],
        series: [
          {
            name: "Attribution",
            data: [
              {
                name: "Bengaluru",
                data: [],
              },
              {
                name: "Chennai",
                data: [],
              },
              {
                name: "Delhi",
                data: [],
              },
              {
                name: "Hyderabad",
                data: [],
              },
              {
                name: "Kolkata",
                data: [],
              },
              {
                name: "Lucknow",
                data: [],
              },
              {
                name: "Mumbai",
                data: [],
              },
            ],
          },
          {
            name: "Cost",
            data: [
              {
                name: "Bengaluru",
                data: [],
              },
              {
                name: "Chennai",
                data: [],
              },
              {
                name: "Delhi",
                data: [],
              },
              {
                name: "Hyderabad",
                data: [],
              },
              {
                name: "Kolkata",
                data: [],
              },
              {
                name: "Lucknow",
                data: [],
              },
              {
                name: "Mumbai",
                data: [],
              },
            ],
          },
        ],
      },
      selectedChannelList: [
        { name: "Conversions", value: "conversions", $isDisabled: false },
        { name: "Cost", value: "cost", $isDisabled: false },
      ],
      lineChartData: {
        colors: ["#050505", "#18ffff"],
        series: [
          {
            data: [1, 2, 3, 3, 2, 1, 4, 5, 3, 7, 9, 10],
            marker: {
              symbol: "circle",
              radius: 5,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "TV Lag effect",
          },
        ],
        title: {
          text: "TV Lag effect",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        legend: {
          align: "right",
          verticalAlign: "top",
          y: -20,
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemMarginBottom: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        xAxis: {
          title: {
            text: "Weeks",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x).options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      threeYearData: [
        {
          name: "Marketing ROI",
          value: "18.31x",
          className: "blue-pastel",
          image: "Marketing ROI.svg",
        },
        {
          name: "Investment",
          value: "408.0Cr",
          className: "green-pastel",
          image: "Investment.svg",
        },
        {
          name: "Media Impacted Revenue ",
          value: "7474.0Cr",
          className: "pink-pastel",
          image: "ROI.svg",
        },
      ],
      threeDimensionalChartData: {
        data: [
          {
            name: "stack 1",
            type: "column",
            data: [64, 38, 45.9, 15.1, 18.3, 91, 14, 64, 38, 45.9, 15.1, 18.3],
            tooltip: {
              valueSuffix: " mm",
            },
            yAxis: 1,
          },
          {
            name: "stack 2",
            type: "column",
            data: [64, 38, 45.9, 15.1, 18.3, 91, 14, 64, 38, 45.9, 15.1, 18.3],
            tooltip: {
              valueSuffix: " mm",
            },
            yAxis: 1,
          },
          {
            name: "stack 3",
            type: "column",
            data: [20.7, 16, 43.8, 18.6, 22.9, 91, 14, 27, 16, 43.8, 18.6, 22.9],
            tooltip: {
              valueSuffix: " mm",
            },
            yAxis: 1,
          },
          {
            name: "line 1",
            data: [2.7, 1.6, 43.8, 18.6, 22.9, 9.1, 1.4, 2.7, 1.6, 43.8, 18.6, 22.9],
            tooltip: {
              valueSuffix: " °C",
            },
          },
        ],
        title: {
          text: "* #5. Monthly Sales and ROI",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: true,
        },
        xAxis: {
          max: 4,
          title: {
            text: "Months",
          },
          categories: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#CCCCCC",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },

          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "",
            },
            title: {
              text: "Sales",
            },
          },
          {
            // Secondary yAxis
            title: {
              text: "ROI",
            },
            labels: {
              format: "",
            },
            opposite: true,
          },
        ],
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
        },

        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },

      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
      salseStatics: [
        {
          name: "Marketing ROI",
          value: "4.026x",
          className: "blue-pastel",
          image: "Marketing ROI.svg",
        },
        {
          name: "Investment",
          value: "103.7r",
          className: "green-pastel",
          image: "Investment.svg",
        },
        {
          name: "ROI",
          value: "418.03Cr",
          className: "pink-pastel",
          image: "ROI.svg",
        },
      ],
      channelDropList: [
        { name: "Impressions", value: "Impressions", $isDisabled: false },
        { name: "Clicks", value: "Clicks", $isDisabled: false },
        { name: "Video Views", value: "Video_views", $isDisabled: false },
        { name: "CTR", value: "CTR", $isDisabled: false },
        { name: "Conversions", value: "conversions", $isDisabled: false },
        { name: "Conv Rate%", value: "conversions_rate", $isDisabled: false },
        { name: "Cost", value: "cost", $isDisabled: false },
        { name: "Cost_conv", value: "Cost_conv", $isDisabled: false },
        { name: "Video View Rate", value: "Video_view", $isDisabled: false },
        { name: "Engagements", value: "Engagements", $isDisabled: false },
        {
          name: "Engagements_rate",
          value: "Engagements_rate",
          $isDisabled: false,
        },
        { name: "CPL", value: "CPL", $isDisabled: false },
        { name: "CPA", value: "CPA", $isDisabled: false },

        { name: "CPC", value: "CPC", $isDisabled: false },
        { name: "CPV", value: "CPV", $isDisabled: false },
        { name: "CPE", value: "CPE", $isDisabled: false },
        { name: "CPM", value: "CPM", $isDisabled: false },
        { name: "VTR", value: "VTR", $isDisabled: false },
      ],
      alltimeData: [
        {
          name: "Marketing ROI",
          value: "16.85x",
          className: "blue-pastel",
          image: "Marketing ROI.svg",
        },
        {
          name: "Investment",
          value: "273.3Cr",
          className: "green-pastel",
          image: "Investment.svg",
        },
        {
          name: "Media Impacted Revenue",
          value: "4605.32Cr",
          className: "pink-pastel",
          image: "ROI.svg",
        },
      ],
      twoLevelStripData: {
        title: "Digital Attribution by Channel",
        stripData: [],
        secondStripData: [],
      },
      twoLevelTVStripData: {
        title: "Television Attribution by Channel",
        stripData: [],
        secondStripData: [],
      },
      twoLevelDigitalPerformanceStripData: {
        title: "Commerce Search",
        stripData: [],
        secondStripData: [],
      },
      overAllData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Overall",
            data: [343010000, 79740000, 70020000],
          },
        ],
        title: {
          text: "Overall",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: ["Baseline", "External Factors", "Internal Factors"],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      driverContributionData: {
        //scrollableWidth: 1200,
        data: [
          // {
          //   name: "Driver contribution",
          //   data: [343010000, 79740000, 70020000],
          // },
          {
            name: "Driver 1",
            data: [-50, 30, 40, 70, 20],
          },
          {
            name: "Driver 2",
            data: [20, 20, -30, 20, 10],
          },
          {
            name: "Driver 3",
            data: [30, -40, 40, -20, -50],
          },
        ],
        title: {
          text: "* #14. Channel Contribution",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: true,
        },
        xAxis: {
          categories: ["January", "February", "March", "April", "May"],
          crosshair: {
            enabled: true,
          },
          title: {
            text: "Months",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          // min: 0,
          title: {
            title: {
              text: "Sales",
            },
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
          plotLines: [
            {
              color: "#a8a8a8",
              width: 1,
              value: 0,
              zIndex: 2,
            },
          ],
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
          },
        },

        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      externalInternalWiseData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Internal Vs External",
            data: [],
          },
        ],
        title: {
          text: "Internal vs External",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: ["Internal", "External"],
          crosshair: {
            enabled: true,
          },

          scrollbar: {
            enabled: true,
            barBackgroundColor: "#CCCCCC",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      invsroiExpandData: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      investRoiData: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      regionWiseData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "External",
            data: [],
          },
        ],
        title: {
          text: "Core",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#CCCCCC",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      internalColumnData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Internal",
            data: [],
          },
        ],
        title: {
          text: "Media",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#CCCCCC",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      stackChartData: {
        //scrollableWidth: 1200,
        data: [],
        title: {
          text: "#11. Marketing Attribution over a period of time",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#67e8ff",
          "#b5a9ff",
          "#81f89e",
          "#ffdd7c",
          "#ffa2a2",
          "#ab9cc9",
          "#d81b60",
          "#fb8c00",
        ],
        legend: {
          enabled: true,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            borderRadiusTopLeft: 0,
            borderRadiusTopRight: 0,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      mediaChartData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Media",
            data: [],
          },
        ],
        title: {
          text: "Attribution by Media Channel",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#CCCCCC",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },

      mediaChartData2: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "",
            data: [],
          },
        ],
        title: {
          text: "ROI by Media Channel",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#CCCCCC",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },

      stackChartData1: {
        //scrollableWidth: 1200,
        data: [
          // {
          //   name: "Print",
          //   data: [],
          // },
          // {
          //   name: "TV",
          //   data: [20, 20, 30, 20],
          // },
          // {
          //   name: "Radio",
          //   data: [3, 4, 4, 2, 5],
          // },
          // {
          //   name: "Prome",
          //   data: [0.5, 3, 4, 0.7, 2],
          // },
          // {
          //   name: "Digital",
          //   data: [20, 20, 30, 20],
          // },
          // {
          //   name: "CRM",
          //   data: [3, 0.4, 0.4, 2, 5],
          // },
          // {
          //   name: "Launch",
          //   data: [0.5, 3, 0.4, 0.7, 2],
          // },
          // {
          //   name: "Outdoor",
          //   data: [0.2, 2, 3, 2, 1],
          // },
          // {
          //   name: "Aggregator",
          //   data: [0.3, 4, 4, 2, 5],
          // },
        ],
        title: {
          text: "#11. Marketing Attribution over a period of time",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#67e8ff",
          "#b5a9ff",
          "#81f89e",
          "#ffdd7c",
          "#ffa2a2",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        legend: {
          enabled: true,
        },
        xAxis: {
          categories: [
            "Last 1 Month",
            "Last 3 Months",
            "Last 6 Months",
            "Last 9 Months",
            "Last 12 Months",
          ],
          crosshair: {
            enabled: true,
          },
          // title: {
          //   text: "All Models",
          // },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            borderRadiusTopLeft: 0,
            borderRadiusTopRight: 0,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      mediaChartData1: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Media",
            data: [],
          },
        ],
        title: {
          text: "Model Comparison over Time Series",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#CCCCCC",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      stackChartData2: {
        data: [
          {
            name: "TV",
            data: [
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
            ],
          },
          {
            name: "Digital",
            data: [
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
            ],
          },
          {
            name: "Print",
            data: [
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
            ],
          },
          {
            name: "Radio",
            data: [
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
            ],
          },
          {
            name: "CRM",
            data: [
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
            ],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#67e8ff",
          "#b5a9ff",
          "#81f89e",
          "#ffdd7c",
          "#ffa2a2",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        legend: {
          enabled: true,
        },
        xAxis: {
          categories: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          crosshair: {
            enabled: true,
          },
          title: {
            text: "Models comparison month wise",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "Performance",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            borderRadiusTopLeft: 0,
            borderRadiusTopRight: 0,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      mediaData: [],
      pieChartColor: ["#ff4081", "#64ffda"],
      chart2Color: [
        "#6a1b9a",
        "#d81b60",
        "#1e88e5",
        "#a71313",
        "#5e35b1",
        "#00acc1",
        "#fb8c00",
        "#d81b60",
        "#64ffda",
      ],
      count: 0,
      breakDown: true,
      billboardData: [],
      contentBreakDown: false,
      activeTab: "Causal Insights",
      activeFrom: 1,
      selectedModel: { text: "Model1", id: "model1" },
      selectedBrand: {
        text: localStorage.getItem("selectedBrandKPI_ACKO"),
        id: localStorage.getItem("selectedBrandKPI_ACKO"),
      },
      selectedMarket: "",
      selectedMedia: {
        text: localStorage.getItem("selectedMediaKPI_ACKO"),
        id: localStorage.getItem("selectedMediaKPI_ACKO"),
      },
      selectedTimeframe: "",
      tabs: [
        {
          category: "Causal Insights",
        },
        // {
        //   category: "ROI Summary",
        // },
        // {
        //   category: "Future Planning",
        // },
      ],
      durationDropdown: [
        { text: "1 Month", id: "1" },
        { text: "3 Months", id: "3" },
        { text: "6 Months", id: "6" },
        { text: "9 Months", id: "9" },
      ],
      brandDropdown: [{ name: "Acko", id: "Acko" }],
      modelsDropdown: [
        { text: "Model 1", id: "model1" },
        { text: "Model 2", id: "model2" },
        { text: "Model 3", id: "model3" },
        { text: "Model 4", id: "model4" },
      ],
      marketDropdown: [{ text: "USA", id: "allusa" }],
      mediaDropdown: [
        { text: "Bengaluru", id: "Bengaluru" },
        { text: "Chennai", id: "Chennai" },
        { text: "Delhi", id: "Delhi" },
        { text: "Hyderabad", id: "Hyderabad" },
        { text: "Kolkata", id: "Kolkata" },
        { text: "Lucknow", id: "Lucknow" },
        { text: "Mumbai", id: "Mumbai" },
        { text: "Overall", id: "Overall" },
        { text: "Others", id: "Others" },
      ],
      TimeframeDropdown: [
        { text: "Last Month", id: "1month" },
        { text: "Last Three Months", id: "3months" },
        { text: "Last Six Months", id: "6months" },
        { text: "Last Nine Months", id: "9months" },
        { text: "Last Year", id: "12months" },
      ],
      overallSeries: [
        {
          name: "Overall",
          colorByPoint: true,
          data: [
            {
              name: "Internal",
              y: 23,
              sliced: true,
            },
            {
              name: "External",
              y: 77,
              sliced: true,
            },
          ],
        },
      ],
      color: [
        "#f77f00",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#08a0e9",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#f77f00",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#08a0e9",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#f77f00",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#08a0e9",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
      ],
      tvData: [
        {
          label: "Google Ads",
          value: 31,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 22,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 17,
          backgroundColor: "#148f3a",
        },
        {
          label: "Publishers",
          value: 15,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 10,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 5,
          backgroundColor: "#08a0e9",
        },
      ],
      printData: [],
      // ChannelPerformanceData: [
      //   {
      //     label: "Google Ads",
      //     value: 31,
      //     backgroundColor: "#f77f00",
      //   },
      //   {
      //     label: "Facebook",
      //     value: 22,
      //     backgroundColor: "#1877f2",
      //   },
      //   {
      //     label: "DV360",
      //     value: 17,
      //     backgroundColor: "#148f3a",
      //   },
      //   {
      //     label: "Publishers",
      //     value: 15,
      //     backgroundColor: "#0077b5",
      //   },
      //   {
      //     label: "Instagram",
      //     value: 10,
      //     backgroundColor: "#dd2a7b",
      //   },
      //   {
      //     label: "Twitter",
      //     value: 5,
      //     backgroundColor: "#08a0e9",
      //   },
      // ],
      digitalData: [
        {
          label: "Google Ads",
          value: 31,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 22,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 17,
          backgroundColor: "#148f3a",
        },
        {
          label: "Publishers",
          value: 15,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 10,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 5,
          backgroundColor: "#08a0e9",
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 61,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 39,
          backgroundColor: "#b5a9ff",
        },
      ],
      externalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "Consumer Sentiment",
          y: 12,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      internalData: [
        {
          name: "Media",
          y: 52,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Product",
          y: 22,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "Promotions",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Distribution",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
        {
          name: "PR",
          y: 4,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id5",
        },
      ],
      contentExpandData: {
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
      },
      tvExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      twoLevelTVBarData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      twoLevelDigitalPerformanceBarData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      twoLevelBarData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: 50,
              x2: 60,
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: 1,
              x2: 20,
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: 20,
              x2: 30,
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: 60,
              x2: 100,
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      printExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      radioExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      oohExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      sponsorshipData: [],
      sponsorshipExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      radioData: [],
      aggData: [],
      oohData: [],
      multipleTitle: ["External", "Internal"],
    };
  },

  created() {
    this.updateCampaignType("Bengaluru");
    this.attributionRangeChart();
    this.callApis();
    // this.secondLevelData(
    //   "investRoiData",
    //   "investmentvsroi",
    //   "/b2c/in/",
    //   "allindia",
    //   "tvdropdown",
    //   "tvTitle",
    //   "invsroiExpandData"
    // );
  },
  methods: {
    updateChannelIndex(val) {
      this.channelData.startIndex = val.startIndex;
      this.channelData.endIndex = val.endIndex;
    },
    handleNext() {
      this.$router.push("/solutions/acko/platform/roi-summary");
    },
    attributionRangeChart() {
      this.stackChartData1.data = [];
      aimoUnileverService
        .getChartData("Acko", "offline", "attributionRange", this.clientId)
        .then((res) => {
          const res_data = res.response;

          const temp = [];

          for (var i = 0; i < res_data.length; i++) {
            temp.push(...res_data[i].value);
          }

          this.stackChartData1.data.push({
            name: "Digital",
            data: [
              temp[0].value,
              temp[3].value,
              temp[6].value,
              temp[9].value,
              temp[12].value,
            ],
          });
          this.stackChartData1.data.push({
            name: "DigitalPerf",
            data: [
              temp[1].value,
              temp[4].value,
              temp[7].value,
              temp[10].value,
              temp[13].value,
            ],
          });
          this.stackChartData1.data.push({
            name: "TV",
            data: [
              temp[2].value,
              temp[5].value,
              temp[8].value,
              temp[11].value,
              temp[14].value,
            ],
          });
          // this.stackChartData1.xAxis.categories.push(res_data[i].name)
        });
    },
    saveToLocal() {
      // if (this.selectedMedia.id && this.selectedBrand.id !== null) {
      localStorage.setItem(
        "selectedKPISource_ACKO",
        `${this.selectedBrand.id}_${this.selectedMedia.id}`
      );
      this.marketType = `${this.selectedBrand.id}_${this.selectedMedia.id}`;
      localStorage.setItem("selectedBrandKPI_ACKO", this.selectedBrand.text);

      this.callApis();

      // localStorage.setItem("selectedBrandKPI", this.selectedKPISource.text)
      // }
    },
    updateCampaignType(val) {
      this.activeChannel = val;
      // this.channelData.series = [];
      this.channelData.series[0].data[0].data = [];
      this.channelData.series[0].data[1].data = [];
      this.channelData.series[1].data[0].data = [];
      this.channelData.series[1].data[1].data = [];
      this.channelData.categories = [];

      // this.channelData.xAxis.categories = []
      //  while (arr.length < 8) {
      //     var r = Math.floor(Math.random() * 100) + 1;
      //     if (arr.indexOf(r) === -1) arr.push(r);
      //   }
      aimoUnileverService
        .getChartData("Acko", val, "attributionbySalesChannel", this.clientId)
        .then((res) => {
          for (var i = 0; i < res.response.result[0].values.length; i++) {
            this.channelData.series[0].data[0].data.push(
              res.response.result[0].values[i].value
            );
            this.channelData.categories.push(res.response.result[0].values[i].name);
          }

          for (var x = 0; x < res.response.result[0].values.length; x++) {
            this.channelData.series[0].data[1].data.push(
              res.response.result[1].values[x].value
            );
            this.channelData.categories.push(res.response.result[1].values[x].name);
          }

          //cost

          for (var y = 0; y < res.response.result[0].values.length; y++) {
            this.channelData.series[1].data[0].data.push(
              res.response.result[0].values[y].value2
            );
            this.channelData.categories.push(res.response.result[0].values[y].name);
          }

          for (var z = 0; z < res.response.result[0].values.length; z++) {
            this.channelData.series[1].data[1].data.push(
              res.response.result[1].values[z].value2
            );
            this.channelData.categories.push(res.response.result[1].values[z].name);
          }

          // console.log(res);
          // this.channelData.series[0].data[0].data.push(123, 123, 123, 123);
        });
      // this.channelData.series.push(
      //   {
      //     name: "Attribution",
      //     data: [
      //       {
      //         name: "Online",
      //         data: [

      //         ],
      //       },
      //       {
      //         name: "Offline",
      //         data: [

      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "Cost",
      //     data: [
      //       {
      //         name: "Online",
      //         data: [

      //         ],
      //       },
      //       {
      //         name: "Offline",
      //         data: [

      //         ],
      //       },
      //     ],
      //   }
      // );
    },

    populateTwoWayCharts() {
      this.channelData.series = [];

      this.channelData.series.data[0].data.push(
        Math.floor(Math.random() * 10000),
        Math.floor(Math.random() * 10000)
      );
    },

    addAllTimeData() {
      this.alltimeData = [];
      this.alltimeData.push(
        {
          name: "Marketing ROI",
          value: Math.floor(Math.random() * 30) + "x",
          className: "blue-pastel",
          image: "Marketing ROI.svg",
        },
        {
          name: "Investment",
          value: Math.floor(Math.random() * 300) + "r",
          className: "green-pastel",
          image: "Investment.svg",
        },
        {
          name: "Media Impacted Revenue",
          value: Math.floor(Math.random() * 10000) + "Cr",
          className: "pink-pastel",
          image: "ROI.svg",
        }
      );
    },
    addsalseStatics() {
      this.salseStatics = [];
      this.salseStatics.push(
        {
          name: "Marketing ROI",
          value: Math.floor(Math.random() * 100) + "x",
          className: "blue-pastel",
          image: "Marketing ROI.svg",
        },
        {
          name: "Investment",
          value: Math.floor(Math.random() * 200) + "r",
          className: "green-pastel",
          image: "Investment.svg",
        },
        {
          name: "ROI",
          value: Math.floor(Math.random() * 1000) + "Cr",
          className: "pink-pastel",
          image: "ROI.svg",
        }
      );
    },
    addModelComparisonData() { },

    clickedTv(data) {
      this.tvTitle = data;
      var removeSpace = false;
      if (data.text.toLowerCase() === "west bengal") {
        removeSpace = true;
      } else {
        removeSpace = false;
      }
      this.getLevelThreeData(
        "invsroiExpandData",
        data.text.toLowerCase(),
        "/b2c/in/",
        "no",
        "",
        removeSpace
      );
    },
    getLevelThreeData(dataVariable, param, inst, market, extraText, removeSpace) {
      var param1 = param;
      if (removeSpace) {
        param1 = param.replace(/\s/g, "");
      }
      if (param1 === "googleads") {
        param1 = "goodgleads";
      } else if (param1 === "programmatic") {
        param1 = "programatics";
      } else if (param1 === "broadcast tv") {
        param1 = "broadcast";
      } else if (param1 === "paid media") {
        param1 = "paid";
      } else if (param1 === "owned content") {
        param1 = "owned";
      } else if (param1 === "magazines") {
        param1 = "magazine";
      } else if (param1 === "atlanta") {
        param1 = "atalanta";
      } else if (param1 === "sports") {
        param1 = "sports games";
      } else if (param1 === "pos displays") {
        param1 = "pos display";
      } else if (param1 === "andhra pradesh") {
        param1 = "ap";
      } else if (param1 === "malayalam") {
        param1 = "malyalam";
      } else if (param1 === "gujarathi") {
        param1 = "gujrati";
      } else if (param1 === "bangalore") {
        param1 = "banagalore";
      } else if (param1 === "tv") {
        param1 = "tvroiinvestmentvsroi";
      }
      aimoSolutionsServices
        .getChartData("roi summary", inst, extraText + param1, market)
        .then((res) => {
          this[dataVariable].data[0].data = [];
          this[dataVariable].data[1].data = [];
          this[dataVariable].xAxis.categories = [];
          if (
            dataVariable === "digitalMedia" ||
            dataVariable === "contentMedia" ||
            dataVariable === "invsroiExpandData" ||
            dataVariable === "printMedia" ||
            dataVariable === "radioMedia"
          ) {
            for (var i = 0; i < res.length; i++) {
              this[dataVariable].data[0].data.push(res[i].Investment);
              this[dataVariable].data[1].data.push(res[i].ROI);
              this[dataVariable].xAxis.categories.push(res[i].name);
            }
          } else {
            for (i = 0; i < res.length; i++) {
              this[dataVariable].data[0].data.push(res[i].Investment);
              this[dataVariable].data[1].data.push(res[i].ROI);
              this[dataVariable].xAxis.categories.push(res[i].name);
            }
          }
        });
    },
    // secondLevelData(
    //   dataVariable,
    //   param,
    //   inst,
    //   market,
    //   dropdownList,
    //   selectDropdown,
    //   thirdLevelData
    // ) {
    //   aimoSolutionsServices
    //     .getChartData("roi summary", inst, param, market)
    //     .then((res) => {
    //       this[dataVariable].data[0].data = [];
    //       this[dataVariable].data[1].data = [];
    //       this[dataVariable].xAxis.categories = [];
    //       this[dropdownList] = [];
    //       for (var i = 0; i < res.length; i++) {
    //         this[dataVariable].data[0].data.push(res[i].Investment);
    //         this[dataVariable].data[1].data.push(res[i].ROI);
    //         this[dataVariable].xAxis.categories.push(res[i].name);
    //         if (res[i].name !== "Minneapolis") {
    //           this[dropdownList].push({
    //             text: res[i].name,
    //             id: res[i].name,
    //             iconHTML: '<i class="fab fa-linkedin"></i>',
    //           });
    //         }
    //         console.log(selectDropdown + thirdLevelData);

    //         // if (i === res.length - 1) {
    //         //   this[selectDropdown] = this[dropdownList][0];
    //         //   if (param === "investment and roi digital") {
    //         //     this.getLevelThreeData(
    //         //       thirdLevelData,
    //         //       this[selectDropdown].text.toLowerCase(),
    //         //       "/b2c/in/",
    //         //       "no",
    //         //       "digital",
    //         //       true
    //         //     );
    //         //   } else if (
    //         //     param === "investment and roi print" ||
    //         //     param === "investment and roi radio" ||
    //         //     param === "investment and roi sponsorship" ||
    //         //     param === "investment and roi billboard"
    //         //   ) {
    //         //     this.getLevelThreeData(
    //         //       thirdLevelData,
    //         //       this[selectDropdown].text.toLowerCase(),
    //         //       "/b2c/in/",
    //         //       this.market.id,
    //         //       "",
    //         //       false
    //         //     );
    //         //   } else if (param === "investment and roi tv") {
    //         //     var name;
    //         //     if (
    //         //       this[selectDropdown].text.toLowerCase() === "broadcast tv"
    //         //     ) {
    //         //       name = "broadcast";
    //         //     } else {
    //         //       name = this[selectDropdown].text.toLowerCase();
    //         //     }
    //         //     this.getLevelThreeData(
    //         //       thirdLevelData,
    //         //       name,
    //         //       "/b2c/in/",
    //         //       this.market.id,
    //         //       "",
    //         //       false
    //         //     );
    //         //   } else if (param === "investment and roi content") {
    //         //     this.getLevelThreeData(
    //         //       thirdLevelData,
    //         //       this[selectDropdown].text.toLowerCase(),
    //         //       "/b2c/in/",
    //         //       "no",
    //         //       "content",
    //         //       false
    //         //     );
    //         //   }
    //         // }
    //       }
    //     });
    // },
    selectedTvDropdown(e) {
      if (e === this.tvTitle || e === this.tvTitle.text) {
        return;
      }
      var removeSpace = false;
      if (e.text.toLowerCase() === "west bengal") {
        removeSpace = true;
      } else {
        removeSpace = false;
      }
      this.getLevelThreeData(
        "invsroiExpandData",
        e.text.toLowerCase(),
        "/b2c/in/",
        "no",
        "",
        removeSpace
      );
      this.tvTitle = e;
    },
    getOverallAttribution() {
      aimoUnileverService
        .getChartData(
          this.selectedBrand.text,
          this.selectedMedia.id,
          "overallattribution",
          this.clientId
        )

        .then((res) => {
          var res_data = res.response;
          this.allMobelBarChart.data = [];
          this.allMobelBarChart.xAxis.categories = [];
          for (var i = 0; i < res_data.length; i++) {
            this.allMobelBarChart.xAxis.categories.push(res_data[i].name);
            for (var j = 0; j < res_data[i].value.length; j++) {
              var dataFind = this.allMobelBarChart.data.find(
                (x) => x.name === res_data[i].value[j].name
              );
              if (dataFind !== undefined) {
                dataFind.data.push(parseInt(res_data[i].value[j].value));
              } else {
                this.allMobelBarChart.data.push({
                  name: res_data[i].value[j].name,
                  data: [parseInt(res_data[i].value[j].value)],
                });
              }
            }
          }
        })
        .catch(() => { });
    },
    callApis() {
      this.count = 0;
      this.selectedMarket = JSON.parse(sessionStorage.getItem("solutionsKpiMarket"));
      // this.getOverallChartData();
      this.getExternalData();
      // this.getInternalExternalData();
      // this.getLagEffectDataData();
      this.getInternalData();
      // this.getMediaData();
      this.getOverallAttribution();
      this.getDigitalData();
      this.getTVData();
      this.getDigitalPerformanceData();
      this.getFirstLevelData("printData", "print");

      // this.getFirstLevelData("radioData", "radio");
      // this.getFirstLevelData("oohData", "ooh");
      // this.getFirstLevelData("aggData", "aggregator");
    },
    getFirstLevelData(dataVariable, param) {
      var color = "";
      if (param === "content") {
        color = "rgb(251,140,0)";
      } else if (param === "tv") {
        color = "rgb(216,27,96)";
      } else if (param === "print") {
        color = "rgb(94,53,177)";
      } else if (param === "radio") {
        color = "rgb(167,19,19)";
      } else if (param === "aggregator") {
        color = "rgb(30,136,229)";
      } else if (param === "digital") {
        color = "rgb(106,27,154)";
      } else {
        color = "rgb(247,127,0)";
      }

      aimoUnileverService
        .getChartData(
          this.selectedBrand.text,
          this.selectedMedia.id,
          "print",
          this.clientId
        )
        .then((res) => {
          var res_data = res.response;
          // var opacity = 1;
          this[dataVariable] = [];
          for (var i = 0; i < res_data[0].levelOne.length; i++) {
            var eachOpacity;
            if (i === 0) {
              eachOpacity = 1;
            } else {
              if (i > 10) {
                eachOpacity = 0.5;
              } else {
                eachOpacity = (100 - i * 10) / 100;
              }
            }
            this[dataVariable].push({
              label: res_data[0].levelOne[i].name,
              value: res_data[0].levelOne[i].value,
              backgroundColor: this.getColor(color, eachOpacity),
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getDigitalBreakdown(name) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          name.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.digitalExpandData = [];
          var count = 0;
          for (var i = 0; i < res.length; i++) {
            this.digitalExpandData.push({
              name: res[i].name,
              pointWidth: 20,
              data: [
                {
                  x: count,
                  x2: count + res[i].value,
                  y: i + 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            });
            count += res[i].value;
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getStripBreakdown(data) {
      // console.log("123", this.twoLevelTVStripData.secondStripData);
      var value = this.twoLevelStripData.secondStripData.find(
        (obj) => obj.name === data.label
      ).value;
      this.secondStripData = value;

      // if(data.label === "GoogleAds") {

      // }

      // data.label === "GoogleAds" ? (this.twoLevelStripData.secondStripData = []) : "";
    },
    getTVStripBreakdown(data) {
      var value = this.twoLevelTVStripData.secondStripData.find(
        (obj) => obj.name === data.label
      ).value;
      this.secondTVStripData = value;
    },
    getDigitalPerformanceStripBreakdown(data) {
      var value = this.twoLevelDigitalPerformanceStripData.secondStripData.find(
        (obj) => obj.name === data.label
      ).value;
      this.secondDigitalPerformanceStripData = value;
    },
    getTVBarDataBreakdown(e) {
      console.log(e)
      this.twoLevelTVBarData.series = [];
      var value = this.twoLevelTVStripData.thirdStripData.find(
        (obj) => obj.name === e.label
      ).value;
      this.thirdTVStripData = value;
      for (var i = 0; i < e.barData.length; i++) {
        this.twoLevelTVBarData.series.push({
          name: e.barData[i].name,
          data: [e.barData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
      }
    },
    getDigitalPerformanceBarDataBreakdown(e) {
      this.twoLevelDigitalPerformanceBarData.series = [];
      for (var i = 0; i < e.barData.length; i++) {
        this.twoLevelDigitalPerformanceBarData.series.push({
          name: e.barData[i].name,
          data: [e.barData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
      }
    },
    getBarDataBreakdown(e) {
      this.twoLevelBarData.series = [];
      for (var i = 0; i < e.barData.length; i++) {
        this.twoLevelBarData.series.push({
          name: e.barData[i].name,
          data: [e.barData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
      }
    },
    getPrintBreakdown(data) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.printExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.printExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getRadioBreakdown(data) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.radioExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.radioExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getOOHBreakdown(data) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.oohExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.oohExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getSponsorshipBreakdown(data) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.sponsorshipExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.sponsorshipExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getContentBreakdown(data) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.contentExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.contentExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    // getBillboardBreakdown(data) {
    //   aimoSolutionsServices
    //     .getChartData(
    //       "sales causality",
    //       "/b2c/in/",
    //       data.label.toLowerCase(),
    //       this.selectedMarket.id
    //     )
    //     .then((res) => {
    //       this.billboardExpandData.series = [];
    //       for (var i = 0; i < res.length; i++) {
    //         this.billboardExpandData.series.push({
    //           name: res[i].name,
    //           data: [res[i].value],
    //           pointWidth: 16,
    //           yAxis: 1,
    //         });
    //       }
    //       this.count++;
    //     })
    //     .catch(() => {
    //       this.count++;
    //     });
    // },
    getTvExpandData(name) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          name.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.tvExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.tvExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    gettvBreakdown(data) {
      this.getTvExpandData(data.label);
    },

    getExternalData() {
      aimoUnileverService
        .getChartData(
          this.selectedBrand.text,
          this.selectedMedia.id,
          "core",
          this.clientId
        )
        .then((res) => {
          var data = res.response;
          this.regionWiseData.data[0].data = [];
          this.regionWiseData.xAxis.categories = [];
          for (var i = 0; i < data.length; i++) {
            this.regionWiseData.data[0].data.push(data[i].value);
            this.regionWiseData.xAxis.categories.push(data[i].name);
          }
        });
    },

    getInternalExternalData() {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          "internalandexternal",
          this.selectedMarket.id
        )
        .then((res) => {
          this.externalInternalWiseData.data[0].data = [];
          this.externalInternalWiseData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.externalInternalWiseData.data[0].data.push(res[i].value);
            this.externalInternalWiseData.xAxis.categories.push(res[i].name);
          }
        });
    },
    getLagEffectDataData() {
      aimoSolutionsServices
        .getChartData("sales causality", "/b2c/in/", "legeffect", this.selectedMarket.id)
        .then((res) => {
          this.lineChartData.series[0].data = [];
          this.lineChartData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.lineChartData.series[0].data.push(res[i].value);
            this.lineChartData.xAxis.categories.push(res[i].name);
          }
        });
    },

    getInternalData() {
      aimoUnileverService
        .getChartData(
          this.selectedBrand.text,
          this.selectedMedia.id,
          "media",
          this.clientId
        )
        .then((res) => {
          var data = res.response;
          this.internalColumnData.data[0].data = [];
          this.internalColumnData.xAxis.categories = [];
          for (var i = 0; i < data.length; i++) {
            this.internalColumnData.data[0].data.push(data[i].value);
            this.internalColumnData.xAxis.categories.push(data[i].name);
          }
        })
        .catch(() => {
          this.count++;
        });
    },
    // getOverallAttribution() {
    //   aimoSolutionsServices
    //     .getChartData(
    //       "sales causality",
    //       "/b2c/in/",
    //       "attributionoveraperiodoftime",
    //       this.selectedMarket.id
    //     )
    //     .then((res) => {
    //       this.stackChartData.data = [];
    //       this.stackChartData.xAxis.categories = [];
    //       for (var i = 0; i < res.length; i++) {
    //         this.stackChartData.xAxis.categories.push(res[i].name);
    //         for (var j = 0; j < res[i].Value.length; j++) {
    //           var dataFind = this.stackChartData.data.find(
    //             (x) => x.name === res[i].Value[j].name
    //           );
    //           if (dataFind !== undefined) {
    //             var value = Math.round(res[i].Value[j].value * 100) / 100;
    //             dataFind.data.push(value);
    //           } else {
    //             value = Math.round(res[i].Value[j].value * 100) / 100;
    //             this.stackChartData.data.push({
    //               name: res[i].Value[j].name,
    //               data: [value],
    //             });
    //           }
    //         }
    //       }
    //       this.count++;
    //     })
    //     .catch(() => {
    //       this.count++;
    //     });
    // },

    getDigitalData() {
      aimoUnileverService
        .getChartData(
          this.selectedBrand.text,
          this.selectedMedia.id,
          "digital",
          this.clientId
        )
        .then((res) => {
          console.log(res);
          let res_data = res.response;
          this.twoLevelStripData.secondStripData = [];
          this.twoLevelStripData.stripData = [];
          for (var i = 0; i < res_data[0].levelOne.length; i++) {
            this.twoLevelStripData.stripData.push({
              label: res_data[0].levelOne[i].name,
              value: res_data[0].levelOne[i].value,
              backgroundColor: this.color[i],
            });
          }

          for (var j = 0; j < res_data[0].levelTwo.length; j++) {
            var data = [];
            for (var k = 0; k < res_data[0].levelTwo[j].value.length; k++) {
              data.push({
                label: res_data[0].levelTwo[j].value[k].name,
                value: res_data[0].levelTwo[j].value[k].value,
                backgroundColor: this.color[k],
                barData: res_data[0].levelTwo[j].value[k].finalLayerData,
              });
            }
            this.twoLevelStripData.secondStripData.push({
              name: res_data[0].levelTwo[j].name,
              value: data,
            });
          }
        });
      // this.twoLevelStripData.title = res.title;
    },

    getTVData() {
      aimoUnileverService
        .getChartData(this.selectedBrand.text, "Overall", "tv", this.clientId)
        .then((res) => {
          var res_data = res.response;
          console.log(res_data);
          this.twoLevelTVStripData.thirdStripData = [];
          this.twoLevelTVStripData.secondStripData = [];
          this.twoLevelTVStripData.stripData = [];
          for (var i = 0; i < res_data[0].levelOne.length; i++) {
            this.twoLevelTVStripData.stripData.push({
              label: res_data[0].levelOne[i].name,
              value: res_data[0].levelOne[i].value,
              backgroundColor: this.color[i],
            });
          }
          for (var j = 0; j < res_data[0].levelTwo.length; j++) {
            var data = [];
            for (var k = 0; k < res_data[0].levelTwo[j].value.length; k++) {
              data.push({
                label: res_data[0].levelTwo[j].value[k].name,
                value: res_data[0].levelTwo[j].value[k].value,
                backgroundColor: this.color[k],
                barData: res_data[0].levelTwo[j].value[k].finalLayerData,
              });
            }
            this.twoLevelTVStripData.secondStripData.push({
              name: res_data[0].levelTwo[j].name,
              value: data,
            });
          }
          for (let j = 0; j < res_data[0].levelThreeList.length; j++) {
            let data = [];
            for (let k = 0; k < res_data[0].levelThreeList[j].value.length; k++) {
              data.push({
                label: res_data[0].levelThreeList[j].value[k].name,
                value: res_data[0].levelThreeList[j].value[k].value,
                backgroundColor: this.color[k],
                barData: res_data[0].levelThreeList[j].value[k].finalLayerData,
              });
            }
            this.twoLevelTVStripData.thirdStripData.push({
              name: res_data[0].levelThreeList[j].name,
              value: data,
            });
          }
        });
    },

    getDigitalPerformanceData() {
      aimoUnileverService
        .getChartData(
          this.selectedBrand.text,
          this.selectedMedia.id,
          "digitalPerformance",
          this.clientId
        )
        .then((res) => {
          var res_data = res.response;
          this.twoLevelDigitalPerformanceStripData.secondStripData = [];
          this.twoLevelDigitalPerformanceStripData.stripData = [];
          for (var i = 0; i < res_data[0].levelOne.length; i++) {
            this.twoLevelDigitalPerformanceStripData.stripData.push({
              label: res_data[0].levelOne[i].name,
              value: res_data[0].levelOne[i].value,
              backgroundColor: this.color[i],
            });
          }
          for (var j = 0; j < res_data[0].levelTwo.length; j++) {
            var data = [];
            for (var k = 0; k < res_data[0].levelTwo[j].value.length; k++) {
              data.push({
                label: res_data[0].levelTwo[j].value[k].name,
                value: res_data[0].levelTwo[j].value[k].value,
                backgroundColor: this.color[k],
                barData: res_data[0].levelTwo[j].value[k].finalLayerData,
              });
            }
            this.twoLevelDigitalPerformanceStripData.secondStripData.push({
              name: res_data[0].levelTwo[j].name,
              value: data,
            });
          }
        });
    },
    getOverallChartData() {
      aimoSolutionsServices
        .getChartData("sales causality", "/b2c/in/", "overall", this.selectedMarket.id)
        .then((res) => {
          this.overAllData.data[0].data = [];
          this.overAllData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.overAllData.data[0].data.push(res[i].value);
            this.overAllData.xAxis.categories.push(res[i].name);
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    getBreakdown(newVal) {
      this.breakDown = newVal;
    },
    selectBrand(e) {
      this.selectedBrand = e;
      this.saveToLocal();
    },
    selectDuration1(e) {
      this.selectedDuration = e;
      this.addModelComparisonData();
      // this.callApis();
    },
    selectModel(e) {
      this.selectedModel = e;
      this.addModelComparisonData();
      // this.callApis();
    },
    selectMedia(e) {
      this.selectedMedia = e;
      // this.addAllTimeData();
      // this.addsalseStatics();
      this.saveToLocal();
      // this.callApis();
    },
    selectTimeframe(e) {
      this.selectedTimeframe = e;
      // this.addAllTimeData();
      // this.addsalseStatics();
      // this.addModelComparisonData();
      // this.callApis();
    },
    selectMarket(e) {
      var defaultOption = JSON.parse(sessionStorage.getItem("solutionsKpiMarket"));
      if (e === "Select") {
        if (e === this.selectedMarket) {
          return;
        }
        this.selectedMarket = defaultOption;
        return;
      }
      if (e.id === this.selectedMarket.id) {
        return;
      }
      sessionStorage.setItem("solutionsKpiMarket", JSON.stringify(e));

      this.selectedMarket = e;
      // this.callApis();
    },
  },

  computed: {
    getColor() {
      return (color, opacity) => {
        var x = color.split(")");
        return x[0] + "," + opacity + ")";
      };
    },

    showPrintAttributionData() {
      switch (this.selectedBrand.text) {
        case "Tresseme":
          return true;
        case "Dove":
          return false;
      }
      return true;
    },
  },

  showPrintAttribution() {
    var model_type = localStorage.getItem("selectedKPISource");
    if (model_type === "Dove") {
      this.isPrintAttributionShown = false;
    }
  },
};
</script>

<style scoped>
.card-heading {
  margin-left: 10px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
  padding-left: 15px;
  padding-top: 15px;
}

.breakdownable-card {
  border-left: 2px solid #d4dae4;
  position: relative;
}

.breakdownable-card::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  z-index: 9999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.breakdownable-card::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #d4dae4;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.item {
  margin-top: 20px;
}

.title {
  font-family: ProximaNovaRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
  margin-bottom: 5px;
}

.dropdown-wrapper {
  position: absolute;
  right: 10px;
  top: 12px;
}

::v-deep .progress-bar {
  text-align: end;
  padding-right: 6px;
}
</style>